import React, { useState, useEffect } from 'react';

import menuIco from '../../../assets/ico/menu.png'

import closeIco from '../../../assets/ico/close.png'

import logo from '../../../assets/logo-blanco.png'

import { useNavigate } from 'react-router-dom';

import './Navbar.scss';

import { Button } from 'library-my-service';

function Navbar() {

	const navigate = useNavigate()

	const [click, setClick] = useState(false);

	const path = window.location.href

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [path])

	const handleClick = () => setClick(!click);

	const closeMobileMenu = () => setClick(false);

	return (

		<div className='fondo-navbar'>

			<div className='title-desktop-div'>

				<div onClick={() => navigate('/')} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<img src={logo} alt='logo' className='title-desktop-logo' />
					<h1 className='title-desktop'>My Service</h1>
				</div>

				<ul className={click ? 'nav-menu active' : 'nav-menu'}>

					<Item to='/' label='Home' closeMobileMenu={closeMobileMenu} navigate={navigate} />

					<Item to='/professionals' label='Profesionales' closeMobileMenu={closeMobileMenu} navigate={navigate} />

					<Item to='/contact' label='Contacto' closeMobileMenu={closeMobileMenu} navigate={navigate} />

					<Item to='/help' label='Ayuda' closeMobileMenu={closeMobileMenu} navigate={navigate} />

					<div className='div-ico-app-mobile'>

						<Button
							label='Descargá la App'
							onClick={() => navigate('/download')}
							theme='light-primary'
						/>

					</div>

				</ul>

			</div>

			<nav className='navbar'>

				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<img src={logo} alt='logo' className='title-mobile-logo' />
					<h1 className='title-mobile'>
						My Service
					</h1>
				</div>

				<div className='menu-icon' onClick={handleClick}>
					<img src={click ? closeIco : menuIco} alt='menu' className='menu-icon' />
					<i className={click ? 'fas fa-times' : 'fas fa-bars'} />
				</div>

			</nav >

			<div className='div-ico-app'>

				<Button
					label='Descargá la App'
					onClick={() => navigate('/download')}
					theme='light-primary'
					scale={80}
				/>

			</div>

		</div>
	);
}

const Item = ({ to, label, closeMobileMenu, navigate }) => (
	<div
		className='div-item'
		onClick={() => {
			closeMobileMenu()
			navigate(to)
		}}
	>
		<p className='texto'>{label}</p>
	</div>
)

export default Navbar;
