import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player';

import * as verification from './checkanimation.json'

const Verified = ({ margin = 16 }) => {

    return (
        <div
            style={{
                marginTop: margin
            }}
        >
            <Player
                autoplay
                // loop
                src={verification}
                style={{ height: 100, width: 100 }}
            >
            </Player>
        </div>
    )
}

export default Verified