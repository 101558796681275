import React, { useEffect } from 'react'

import { Contenedor } from 'library-my-service';

import Loading from '../../components/loading/Loading';

import { useNavigate } from 'react-router-dom';

const Auth = () => {

    const navigate = useNavigate()

    const urlParams = new URLSearchParams(window.location.search)
    const mode = urlParams.get('mode')
    const oobCode = urlParams.get('oobCode')

    useEffect(() => {
        if (mode === 'verifyEmail') navigate(`/verify-account/?mode=${mode}&oobCode=${oobCode}`)
        if (mode === 'resetPassword') navigate(`/change-password/?mode=${mode}&oobCode=${oobCode}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Contenedor marginTop={1}>
            <Loading />
        </Contenedor>
    );
}

export default Auth;