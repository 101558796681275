import React from 'react'

import Home from './modules/Home';

const App = () => {
  return (
    <Home/>
  );
}

export default App;