import { useEffect, useState } from 'react';

const UseApi = (endpoint, config) => {

    const [isLoading, setIsLoading] = useState(false)

    const [data, setData] = useState(null)

    const [isError, setIsError] = useState(false)

    const {
        method,
        callOnLoad = false,
    } = config

    const callApi = async (dataApi = {}, addEndpoint = '') => {

        setIsLoading(true)

        setIsError(false)

        let response = null
        let hasError = false

        try {

            const completeOptions = {
                method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'auth-token': getToken()?.toString() || ''
                },
                body: JSON.stringify(dataApi)
            }

            const { body, ...optionsGet } = completeOptions

            const options = method === 'GET' ? optionsGet : completeOptions

            await fetch(process.env.REACT_APP_BACKEND + endpoint + addEndpoint || '', options).then(async (responseApi) => {

                    const data = await responseApi.json()
                    const status = await responseApi.status

                    if(status === 200){
                        setData(data || null)

                        response = data || null

                        setIsError(false)
                    }else{

                        const code = data.error || ''
                        const msg = 'Ha ocurrido un error, intente más tarde'
                        setData({ code, msg })
    
                        response = { code, msg }
    
                        hasError = true
    
                        setIsError(true)
                        
                    }
                    

                }).catch((error) => {

                    console.log(error)

                    const {
                        code = '',
                        msg = 'Ha ocurrido un error, intente más tarde'
                    } = error?.response?.data

                    setData({ code, msg })

                    response = { code, msg }

                    hasError = true

                    setIsError(true)

                }).finally(() => setIsLoading(false))


        } catch (error) {

            setData({ code: "GENERIC", msg: 'Ha ocurrido un error, intente más tarde' })

            setIsError(true)

            setIsLoading(false)

        }

        return { response, hasError }

    }

    useEffect(() => {
        if (callOnLoad) callApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isLoading,
        isError,
        data,
        callApi,
    }

}

export default UseApi