import React from 'react'

import { Contenedor, Subtitle, Text } from 'library-my-service';

const TermsAndConditions = () => {

    return (
        <Contenedor marginTop={1}>

            {/* <Title label='Términos y condiciones' /> */}

            <Text
                label='Estas condiciones de uso, (“Condiciones Generales”) constituyen un contrato entre cualquier persona (en adelante, “Usuario” o, en plural, “Usuarios”) que desee acceder y/o usar el servicio de My Service, gestionado por Gianluca Loguercio, CUIT: 20-42101028-6, con domicilio en la calle Pedro Morán 5037, Ciudad de Buenos Aires (Intermediario digital).'
                style={{ marginTop: 8, fontSize: 14 }}
            />

            <Text
                label='En estas Condiciones Generales se describen los derechos, responsabilidades y obligaciones del Usuario y de My Service al utilizar el servicio de digitalización de servicios para el hogar, la Plataforma (conforme es definida en estas Condiciones Generales) y cualquier producto o servicio relacionado que ofrezca My Service, cualquiera de ellos, indistintamente, el “Servicio” y, conjuntamente, los “Servicios”, según corresponda).'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='El Usuario debe leer, entender y aceptar todas las condiciones establecidas en estas Condiciones Generales, incluyendo las políticas y principios incorporados a las mismas por referencia, previo a su registro como Usuario de My Service  y/o la utilización del Servicio. En cualquier caso, la utilización del Servicio implica la aceptación por parte del Usuario de las Condiciones Generales.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='Cualquier persona que no acepte estos términos y condiciones generales, los cuales tiene un carácter obligatorio y vinculante, deberá abstenerse de utilizar el sitio y/o los Servicios.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Subtitle
                label='1. Registro de cuentas'
                isBold
                style={{ marginTop: 16 }}
            />

            <Text
                label='a) Registro. Todo Usuario que desee utilizar el Servicio de La Plataforma debe registrarse en My Service y aceptar estas Condiciones Generales, incluso en caso de registración abreviada bajo la modalidad “invitado” o “guest”. Para registrarse es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal exacta, precisa y verdadera ("Datos Personales"). El Usuario asume el compromiso de actualizar los Datos Personales conforme resulte necesario. Todos los Usuarios deberán pasar un proceso de verificación interno para poder registrarse. My Service no se responsabiliza por la certeza de los Datos Personales provistos por sus Usuarios. Los Usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los Datos Personales ingresados. Toda la información y los Datos Personales ingresados por el Usuario tienen carácter de declaración jurada. Los datos personales indispensables para la registración incluyen, nombre, apellido, dirección y correo electrónico. En el caso de Prestadores de Servicios además deberán brindar los datos de su matrícula.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='Además deberá adjuntarse foto de DNI (frente y dorso) más una foto con el DNI superpuesto sobre el rostro.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='My Service  se reserva el derecho de solicitar comprobantes y/o información adicional a efectos de corroborar la información entregada por un Usuario en materia de Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='My Service se reserva el derecho de rechazar una solicitud de registro o de cancelar o suspender, temporal o definitivamente una Cuenta de My Service  (conforme es definida a continuación), en caso de detectar incongruencias o inconsistencias en la información provista por un Usuario o en caso de detectar actividades sospechosas, sin que tal decisión genere para el Usuario derechos de indemnización o resarcimiento.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='b) Cuenta My Service. Al registrarse en My Service, el Usuario abrirá una cuenta en My Service  (en adelante, la "Cuenta My Service"). La Cuenta My Service permitirá al Usuario utilizar el Servicio, como también utilizar o aplicar cualquier otra funcionalidad que en un futuro se ofrezca al Usuario, sujeto a los términos y condiciones que se establezcan para cada funcionalidad.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='El Usuario accederá a su Cuenta My Service  mediante el ingreso de su dirección de e-mail y clave de seguridad personal elegida ("Clave de Ingreso"). El Usuario se obliga a mantener la confidencialidad de su Clave de Ingreso. En virtud de ello, el Usuario será el único y exclusivo responsable por todas las operaciones efectuadas en su Cuenta My Service. El Usuario se compromete a notificar a My Service  en forma inmediata y por medio idóneo y fehaciente, de cualquier uso no autorizado de su Cuenta My Service, así como del ingreso o de intentos de ingreso por terceros no autorizados a la misma.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='La Cuenta My Service, única e intransferible, será asignada a un solo Usuario y está prohibida su venta, cesión o transferencia (incluyendo la reputación del Usuario) bajo ningún título. El Usuario no podrá permitir ni autorizar el uso de su Cuenta My Service por terceras personas.  Cualquier referencia a dinero en My Service se entenderá hecha a la moneda de curso legal en la República Argentina, salvo en los casos que se indique expresamente otra moneda.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Subtitle
                label='2. Definiciones y partes'
                isBold
                style={{ marginTop: 16 }}
            />

            <Text
                label='Existen dos tipos de Usuarios:'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='Consumidores: usuario que requiere un servicio de soluciones para el hogar.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='Prestadores  de servicios: las personas humanas o jurídicas que ofrecen servicios de soluciones para el hogar (electricistas, cerrajeros, gasistas, etc.).'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='La contraparte es el: Intermediario digital: My Service, quien ofrece el servicio de intermediación entre prestadores de servicios adheridos y los consumidores o usuarios  a través de la plataforma digital.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Subtitle
                label='3. El servicio'
                isBold
                style={{ marginTop: 16 }}
            />

            <Text
                label='3.1. Servicio My Service – Alcance.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='My Service es una aplicación que únicamente digitaliza servicios para el hogar siendo enlace entre “CONSUMIDORES” con “PRESTADORES DE SERVICIOS”, exclusivamente dentro del radio correspondiente a la Comuna 11 de la Ciudad de Buenos Aires.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='El Servicio My Service  solo incluye:'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='a) la conexión entre personas que requieren un servicio en su hogar (Consumidores) y las personas que brindan la solución (Prestadores de Servicios)'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='b) La captura y transmisión de información necesaria.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='c) El otorgamiento de un espacio para que, por un lado los Prestadores de Servicios publiquen su perfil, concentren reseñas, trabajos y puntuaciones y por otro lado que los Consumidores accedan a un espacio donde poder chequear perfiles profesionales, reseñas de trabajos puntuaciones y realizar consultas, como asimismo registrar comienzo y finalización del trabajo una convenido entre Prestador de Servicio y Consumidor.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='d) El otorgamiento de un Código de Seguridad Único para Prestadores de Servicios y Consumidores como medida de Seguridad para el momento del contacto físico entre el Prestador del Servicio y el Consumidor.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='3.2. El Usuario será el único responsable de la negociación y contratación con los PRESTADORES DE SERVICIO, y por tanto My Service Service no tendrá ningún tipo de responsabilidad por obligaciones derivadas de dicha relación contractual, ya sea por la debida prestación del servicio por parte del prestador adherido a My Service, así como por los pagos al PRESTADOR DEL SERVICIO por parte del CONSUMIDOR.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='3.3. Indemnidad. La Persona Usuaria mantendrá indemne a My Service, así como a quienes la dirigen, suceden, administran, representan y/o trabajan en ellas, por cualquier reclamo administrativo o judicial iniciado por otras Personas Usuarias, terceros o por cualquier Organismo, relacionado con sus actividades en La Plataforma o derivada de la utilización de los Servicios de My Service. En virtud de esa responsabilidad, My Service podrá reclamar compensaciones, retenciones u otras medidas necesarias para la reparación de pérdidas, daños y perjuicios, cualquiera sea su naturaleza.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='3.4. My Service se reserva el derecho de cambiar, modificar y/o alterar la plataforma tecnológica que brinda el Servicio total o parcialmente, de acuerdo a su exclusivo criterio o necesidades del negocio.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Subtitle
                label='4. Propiedad intelectual'
                isBold
                style={{ marginTop: 16 }}
            />

            <Text
                label='4.1. La tecnología, los desarrollos, fases de integración y toda la aplicación que conforma los Servicios de My Service son de exclusiva propiedad del intermediario digital, incluyendo su funcionalidad, códigos, arquitectura, apariencia visual e implementaciones.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='4.2. El Servicio My Service  será facilitado por medio de un entorno digital, para facilitar que el Usuario tenga acceso a los Servicios.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='4.3. El Usuario quedará irrevocablemente obligado a hacer buen uso del Servicio de My Service, debiendo cumplir las exigencias de seguridad requeridas por la Plataforma. El Usuario se obliga a utilizar el Servicio  únicamente en su beneficio y con la finalidad de procesar sus necesidades personales, teniendo estrictamente prohibido compartir o ceder su uso, integraciones y demás componentes del Servicio de la Plataforma.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Subtitle
                label='5. Protección de datos – Tratamiento de datos'
                isBold
                style={{ marginTop: 16 }}
            />

            <Text
                label='My Service App no venderá, alquilará ni negociará con otras empresas la información personal de los Usuarios salvo en las formas y casos establecidas en las políticas de privacidad. Estos datos serán utilizados para prestar el Servicio de My Service.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='My Service hará sus mejores esfuerzos para mantener la confidencialidad y seguridad de que trata esta sección, pero no responderá por perjuicios que se puedan derivar de la violación de dichas medidas por parte de terceros que utilicen las redes públicas o Internet para acceder a dicha información o en los casos en que haya habido culpa o negligencia del Usuario, y/o si el Usuario hubiera otorgado permisos a terceros distintos de My Service para acceder a sus datos personales.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Subtitle
                label='6. Condiciones generales de contratación'
                isBold
                style={{ marginTop: 16 }}
            />

            <Text
                label='a) Capacidad. Sólo podrán contratar los Servicios de My Service aquellas personas que tengan capacidad legal para contratar y estén debidamente registrados como usuarios en las condiciones establecidas en 1. Registro de Cuenta. No podrán registrarse ni utilizar los servicios menores de edad, personas que no tengan capacidad para contratar o aquellos Usuarios que hayan sido suspendidos o inhabilitados por La Plataforma, temporal o definitivamente.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='b) Declaraciones del Usuario. Usos Prohibidos. El Usuario manifiesta que el objeto por el cual efectúa la contratación de los Servicios de My Service no infringe ninguna ley aplicable, ni será utilizado para brindar un servicio, venta o transmisión que está prohibida por la ley o las Condiciones Generales, tales como y sin limitarse a: (i) juegos de azar, apuestas, etc.; (ii) tráfico de armas, de personas, de animales, etc.; (iii) lavado de dinero y/o terrorismo; (iv) pedofilia; (v) cualquier tipo de actividad que pueda ser considerada fraudulenta, ilegal o sospechosa de serlo.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='El Usuario no utilizará los Servicios de My Service App para acciones que constituyan un delito o bien que pueda dar lugar, directa o indirectamente a responsabilidades civiles, penales y/o que infrinjan estas Condiciones Generales.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='Si My Service considera que hay una sospecha o indicio de la utilización del Servicio para alguna actividad prohibida por la ley y/o estas Condiciones Generales, podrá rechazar, cancelar o suspender el uso de los Servicios, y/o bloquear temporalmente el acceso y uso de una Cuenta My Service App y/o la utilización o disponibilidad de funcionalidades y/o cancelar definitivamente una Cuenta My Service App. En tal caso, el Usuario responderá por los daños y perjuicios que pueda haber ocasionado a My Service, sus controlantes, controladas, filiales o subsidiarias, funcionarios, empleados, directores, agentes, y/o empleados, a cuyos efectos el intermediario Digital se reserva el derecho de iniciar las acciones judiciales o extrajudiciales que estime pertinentes.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='El Usuario será exclusiva e ilimitadamente responsable por los perjuicios que su conducta pueda causar a My Service o a los restantes Usuarios de los servicios prestados por La Plataforma.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='c) Utilización del servicio por cuenta propia. Solamente se permite utilizar los Servicios en nombre propio y no en representación y/o por cuenta de terceros. El Usuario no revenderá los Servicios a terceros.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='d) Limitación de Responsabilidad por el Servicio y/o la Plataforma. My Service  no garantiza el acceso y uso continuado o ininterrumpido de su Sitio, o del Servicio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet en los links o por cualquier otra circunstancia ajena a My Service. Los Usuarios NO podrán imputar responsabilidad alguna a My Service, ni exigir resarcimiento alguno, en virtud de perjuicios resultantes de las mencionadas dificultades, así como por cualquier otra clase de daños, incluyendo daños indirectos, especiales o consecuentes que surjan o experimenten los Usuarios.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='e) Licencia Limitada. Todos los derechos intelectuales e industriales, sobre el Sitio, códigos, desarrollo, software, hardware, dominio, logos, emblemas, logotipos, diseños, estructura, contenidos, información, etc. son de propiedad de My Service y/o quien la gestiona o gestione en el futuro y/o sus sociedades controlantes, controladas, filiales o subsidiarias. En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho sobre los mismos, independientemente de los derechos conferidos que My Service  pone a disposición del Usuario durante la vigencia de este acuerdo.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='My Service sólo autoriza al Usuario a hacer uso de su propiedad intelectual para el cumplimiento de las actividades que se derivan de estas Condiciones Generales. Cualquier otra utilización de tal propiedad intelectual de My Service queda estrictamente prohibida.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='Los logos, emblemas, logotipos, diseños, estructura etc. son de propiedad exclusiva de quien gestiona La Plataforma. Los Usuarios declaran conocer y aceptar que su uso está permitido únicamente con el objeto de hacer uso de La Plataforma en los términos aquí descriptos. No se entenderá que el Usuario tendrá algún tipo de derecho sobre los mismos. Los Usuarios no utilizarán las marcas, logos, emblemas, logotipos, diseños, estructuras, etc. Asimismo, los Usuarios deberán abstenerse de realizar cualquier acto que pudiera causar un daño, pérdida de reputación, o disminución del valor de dichas marcas, o utilizarlas en forma contraria a la moral y buenas costumbres.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='f) Modificaciones de las Condiciones Generales. My Service podrá modificar en cualquier momento estas Condiciones Generales. My Service  notificará los cambios al Usuario publicando una versión actualizada de dichas Condiciones Generales en el sitio con expresión de la fecha de la última modificación. Todos los términos modificados entrarán en vigor a los 10 (diez) días de su publicación. Dentro de los 10 (diez) días siguientes a la publicación de las modificaciones introducidas, el Usuario deberá comunicar por e-mail si no acepta las mismas; en ese caso quedará disuelto el vínculo contractual. Vencido este plazo, se considerará que el Usuario acepta los nuevos términos y el contrato continuará vinculando a ambas partes.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='g) Cesión. El Usuario no podrá ceder, transferir, delegar o disponer de los derechos u obligaciones derivados de estas Condiciones Generales, total o parcialmente. Quien gestiona My Service podrá ceder, transferir, delegar o disponer de los derechos u obligaciones derivados de las Condiciones Generales o su posición contractual, total o parcialmente, para lo cual el Usuario otorga su consentimiento de manera previa para la realización de dichas acciones.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='h) Terminación del Contrato. El Intermediario Digital y el Usuario podrán en cualquier oportunidad durante la vigencia del presente contrato terminarlo sin expresión de causa alguna, lo que implicará el cese en la prestación de los Servicios, pero no suspenderá el cumplimiento de las transacciones ya procesadas al momento de notificar la rescisión. A los efectos de ejercer esta facultad, es necesario que la parte que pretende la terminación del contrato no adeude a la otra ni a terceros involucrados en las operaciones el cumplimiento de alguna obligación.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='Asimismo, My Service podrá, en caso de incumplimiento del Usuario a las condiciones del presente contrato o a la legislación aplicable en la materia, dar por terminada la prestación de los Servicios sin ningún tipo de aviso previo, reservándose el derecho de reclamar los daños y perjuicios que tal incumplimiento le haya causado.'
                style={{ fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='i) Documentación. El Usuario opta expresamente que la documentación e información relacionada con las Condiciones Generales provista por My Service tenga, salvo disposición expresa en contrario, soporte electrónico. En todo caso, el Usuario podrá hacer copias, incluso en soporte físico, de la documentación e información provista por My Service. El Usuario autoriza en forma irrevocable a My Service y/o a quien el Intermediario digital y/o la persona que la gestione designe, a grabar las operaciones del Usuario relativas a los servicios prestados por My Service, y a utilizar dichas grabaciones como medio probatorio ante autoridades administrativas y/o judiciales. Las constancias emitidas por My Service  serán consideradas prueba suficiente del cumplimiento de los Servicios prestados por La Plataforma, y sustituyen la necesidad de cualquier recibo y son plenamente oponibles al Usuario. A tales efectos, el Usuario y My Service acuerdan otorgarle valor probatorio a las constancias que se emitan como consecuencia de las aprobaciones o instrucciones dadas por el Usuario, con el alcance establecido en la legislación vigente.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='j) Notificaciones. Serán válidas y el Usuario acepta expresamente que todas las notificaciones realizadas a los Usuarios por My Service, incluyendo sin limitación a los avisos, recordatorios o comunicaciones relacionadas con los Servicios brindados por My Service, sean dirigidas (i) a la dirección de correo electrónico principal registrada por éstos (domicilio especial electrónico), (ii) mediante la aplicación móvil y/o (iii) a la Cuenta My Service del Usuario.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='k) Domicilios. Se fija como domicilio de My Service Pedro Morán 5037, CABA. El domicilio constituido por los Usuarios a los fines de estas Condiciones Generales es el domicilio registrado por los Usuarios en la Cuenta My Service App.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

            <Text
                label='l) Jurisdicción y ley aplicable. Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la Argentina. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a los tribunales nacionales en lo comercial de la Ciudad de Buenos Aires.'
                style={{ marginTop: 8, fontSize: 14, textAlign: 'justify' }}
            />

        </Contenedor>
    );
}

export default TermsAndConditions;