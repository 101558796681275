import React from 'react'

import { Subtitle, Title, Text } from 'library-my-service';

import './HomeScreen.scss'

import home1 from '../../../assets/home/home-1.png'
import hand from '../../../assets/home/hand.webp'

import screen1 from '../../../assets/home/screenshot-1.webp'
import screen2 from '../../../assets/home/screenshot-2.webp'
import screen3 from '../../../assets/home/screenshot-3.webp'

import { colors } from '../../../styles/colors'

const HomeScreen = () => {
    return (

        <>

            <img
                src={home1}
                className='header-img'
                alt='home-1'
            />

            <div className='home-contenedor'>

                <div className='hand-what'>

                    <img
                        src={hand}
                        className='hand-img'
                        alt='home-1'
                    />

                    <div className='texts-hand'>

                        <Title
                            label='¿Qué es My Service?'
                            isCenter
                            color='white'
                            isBold
                            style={{ fontWeight: 600 }}
                        />

                        <p style={{ marginTop: 16, color: 'white' }}>
                            MyService es una aplicación móvil (APP) que conecta profesionales que brindan soluciones con clientes que necesitan sus servicios.
                        </p>

                        <p style={{ marginTop: 16, color: 'white' }}>
                            La aplicación se destaca por ofrecer una experiencia <b>fácil de usar</b> y <b>segura</b> para ambas partes.
                        </p>

                    </div>

                </div>

                <Title
                    label='Nuestro Pilares'
                    isCenter
                    isBold
                    style={{ marginTop: 32, fontWeight: 600 }}
                />

                <Subtitle
                    label='Estamos con vos, simplifica tu negocio con My Service'
                    isCenter
                    style={{ marginTop: 16, fontWeight: 300 }}
                />

                <div className='screenshots-div'>
                    <div className='screenshot-div'>
                        <img
                            src={screen1}
                            className='screenshot-img'
                            alt='screenshot-1'
                        />
                        <div>
                            <Subtitle
                                label="Profesionales verificados"
                                isBold
                                isCenter
                                style={{ fontWeight: 500 }}
                            />
                            <Text
                                label='A la hora de registrarse como "Profesional" pasan por un proceso de verificación, lo que garantiza que los usuarios obtengan servicios de calidad y confiables.'
                                style={{ textAlign: 'center', marginTop: 16 }}
                            />
                        </div>
                    </div>
                    <div className='screenshot-div'>
                        <img
                            src={screen2}
                            className='screenshot-img'
                            alt='screenshot-2'
                        />
                        <div>
                            <Subtitle
                                label="Seguridad"
                                isBold
                                isCenter
                                style={{ fontWeight: 500 }}
                            />
                            <Text
                                label='La aplicación proporciona un código de seguridad al contratar un servicio, lo que garantiza que los usuarios obtengan servicios de calidad y confiables'
                                style={{ textAlign: 'center', marginTop: 16 }}
                            />
                        </div>
                    </div>
                    <div className='screenshot-div'>
                        <img
                            src={screen3}
                            className='screenshot-img'
                            alt='screenshot-3'
                        />
                        <div>
                            <Subtitle
                                label="Diseño fácil de usar"
                                isBold
                                isCenter
                                style={{ fontWeight: 500 }}
                            />
                            <Text
                                label='Nuestra aplicación es accesible para todos los usuarios, independientemente de su nivel de experiencia con la tecnología.'
                                style={{ textAlign: 'center', marginTop: 16 }}
                            />
                        </div>
                    </div>
                </div>

                <h2 className='soluciones-text'>
                    Soluciones <span style={{ color: colors.primary }}>rápidas</span> y <span style={{ color: colors.primary }}>confiables</span>
                </h2>

                <p className='mt1 text-center'>
                    Hacemos tu vida es <b>más fácil</b>
                </p>

                <p className='mt1 mb text-center'>
                    Mantén la tranquilidad sabiendo que cada profesional ha sido evaluado para garantizar la calidad y la seguridad.
                </p>

            </div>

        </>
    );
}

export default HomeScreen;