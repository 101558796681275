import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player';

import * as loading from './loading.json'

const Loading = ({ margin = 100}) => {
    
    return (
        <div
            style={{
                marginTop: margin,
            }}
        >
            <Player
                autoplay
                loop
                src={loading}
                style={{ height: 100, width: 100 }}
            >
            </Player>
        </div>
    )

}
 
export default Loading;