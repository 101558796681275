import React, { useEffect, useState } from 'react'

import { Contenedor, Input, Text, TextArea, Button, Subtitle } from 'library-my-service';

import { WrapperForm } from './styled'

import useNewInquiery from '../../../services/inquieries/useNewInquiery'

import Loading from '../../components/loading/Loading';

import { useNavigate } from 'react-router-dom';

const Contact = () => {

    const navigate = useNavigate()

    const [form, setForm] = useState({
        email: '',
        subject: '',
        description: ''
    })

    const { email, subject, description } = form

    const { data, isLoading, isError, callApi } = useNewInquiery()

    const send = () => {

        if (email.trim() === '' || subject.trim() === '' || description.trim() === '') {
            console.log('por favor complete todos los datos')
            return
        }

        callApi({
            email,
            subject,
            description,
        })

    }

    useEffect(() => {
        if (!isLoading && !isError && data?.sended) {
            navigate('/contact/sended')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    if (isLoading) {
        return (
            <Contenedor marginTop={1}>
                <Loading />
            </Contenedor>
        )
    }

    return (
        <Contenedor marginTop={1}>

            <Subtitle
                label='Contactanos'
                isBold
                style={{ marginTop: 16 }}
            />

            <Text
                label='Nos encantaría saber de vos y responder cualquier pregunta o inquietud que puedas tener. Por favor, completá el siguiente formulario con tus datos y nos pondremos en contacto lo antes posible.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='También podes enviarnos un correo directamente a nuestro correo electrónico de contacto o llamar a nuestro número de atención al cliente. Estamos disponibles para responder tus preguntas de lunes a viernes de 9:00 a.m. a 6:00 p.m.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='No dudes en comunicarte con nosotros si tienes cualquier problema con nuestros productos o servicios, si necesitás ayuda, o simplemente si quieres dejarnos un comentario o sugerencia.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='Nos tomamos en serio tus comentarios y estamos siempre dispuestos a mejorar nuestros servicios para satisfacer tus necesidades.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='¡Esperamos poder ayudarte pronto!'
                isBold
                style={{ marginTop: 16, textAlign: 'right' }}
            />

            <WrapperForm>

                <Input
                    label='Email'
                    placeholder='Ingresá tu email...'
                    value={email}
                    onChange={e => setForm({ ...form, email: e.target.value.trim() })}
                    type='email'
                    style={{ marginTop: 16 }}
                />

                <Input
                    label='Asunto'
                    placeholder='Ingresá el asunto...'
                    value={subject}
                    onChange={e => setForm({ ...form, subject: e.target.value })}
                    style={{ marginTop: 16 }}
                />

                <TextArea
                    label='Descripción'
                    placeholder='Ingresá tu consulta / duda / sugerencia...'
                    value={description}
                    onChange={e => setForm({ ...form, description: e.target.value })}
                    style={{ marginTop: 16 }}
                    height={150}
                />

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        label='Enviar'
                        onClick={send}
                        isBold
                        theme='primary'
                        style={{ marginTop: 16 }}
                    />
                </div>

            </WrapperForm>

        </Contenedor>
    )
}

export default Contact