import React from 'react'

import { Routes, BrowserRouter, Route } from 'react-router-dom'

import Navbar from '../components/top-bar/Navbar'

import HomeScreen from './home-screen/HomeScreen'
import ChangePassword from './change-password/ChangePassword'
import VerifyAccount from './verify-account/VerifyAccount'
import Contact from './contact/Contact'
import Footer from '../components/footer/Footer'
import DownloadAppScreen from './download-app/DownloadAppScreen'
import Sended from './contact/Sended'
import Auth from './auth-page/Auth'
import TermsAndConditions from './terms-and-conditions/TermsAndConditions'
import ProfessionalsScreen from './professionals/Professionals'
import FrecuentsQuestionsScreen from './frequents-questions/FrecuentsQuestionsScreen'

const Home = () => {
    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <div>
                <BrowserRouter>

                    <Navbar />

                    <Routes>

                        <Route path="/" element={<HomeScreen />} />

                        <Route path="/verify-account" element={<VerifyAccount />} />

                        <Route path="/change-password" element={<ChangePassword />} />

                        <Route path="/contact" element={<Contact />} />

                        <Route path="/contact/sended" element={<Sended />} />

                        <Route path="/download" element={<DownloadAppScreen />} />

                        <Route path="/auth" element={<Auth />} />

                        <Route path="/legales" element={<TermsAndConditions />} />

                        <Route path="/professionals" element={<ProfessionalsScreen />} />

                        <Route path="/help" element={<FrecuentsQuestionsScreen />} />

                    </Routes>

                </BrowserRouter>

            </div>

            <Footer/>

        </div>
    );
}

export default Home;