import React, { useState, useEffect } from 'react'

import { Button, Contenedor, Input, Text, Title } from 'library-my-service'

import useChangePassword from '../../../services/auth/useChangePassword'

import Modal from '../../components/modal/Modal'

import Loading from '../../components/loading/Loading'

import { useNavigate } from 'react-router-dom'

const ChangePassword = () => {

    const navigate = useNavigate()

    const urlParams = new URLSearchParams(window.location.search)
    const oobCode = urlParams.get('oobCode')

    const { isLoading, isError, data, callApi } = useChangePassword()

    const showModal = (show) => setModal({ ...modal, show, })

    const [password, setPassword] = useState('')
    const [passwordConf, setPasswordConf] = useState('')
    const [modal, setModal] = useState({
        show: false,
        title: 'Título',
        description: 'Descripción',
        pbText: 'Entendido',
        pbTheme: 'secondary',
        pbOnClick: () => {
            showModal(false)
        },
    })

    useEffect(() => {
        if (!isLoading && !isError && data?.changePassword) {
            setModal({
                ...modal,
                show: true,
                title: 'Contraseña modificada',
                description: 'Se ha modificado la contraseña de forma correcta',
                pbText: 'Entendido',
                pbTheme: 'secondary',
                pbOnClick: () => {
                    showModal(false)
                    navigate('/')
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError, isLoading])

    const showModalError = (msgError) => {
        setModal({
            ...modal,
            show: true,
            title: 'Campos incorrectos',
            description: msgError,
        })
    }

    const confirm = () => {

        if (password.trim() === '' || passwordConf.trim() === '') {
            showModalError('Por favor complete las dos contraseñas')
            return
        }
        if (password.trim() < 6) {
            showModalError('La contraseña debe tener 6 dígitos o más')
            return
        }
        if (password.trim() !== passwordConf.trim()) {
            showModalError('Las contraseñas no coinciden')
            return
        }

        callApi({ oobCode, newPassword: password })

    }

    if (isLoading) {
        return (
            <Contenedor marginTop={1}>
                <Loading />
            </Contenedor>
        )
    }

    return (
        <Contenedor marginTop={1}>

            <Title label='Cambiar contraseña' />

            <Text
                label='Acá podes actualizar la contraseña de tu cuenta para garantizar la seguridad de tu información personal. Es importante que elijas una contraseña segura y única, preferentemente que no hayas utilizado en otras cuentas y que contenga una combinación de letras, números y símbolos.'
                style={{ marginTop: 16 }}
            />

            <Input
                label='Nueva contraseña'
                placeholder='Contraseña...'
                value={password}
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginTop: 16 }}
            />

            <Input
                label='Confirmar nueva contraseña'
                placeholder='Ingresa nuevamente la contraseña...'
                value={passwordConf}
                type='password'
                onChange={(e) => setPasswordConf(e.target.value)}
                style={{ marginTop: 16 }}
            />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    label='Confimar cambio de contraseña'
                    onClick={confirm}
                    isBold
                    theme='secondary'
                    style={{ marginTop: 16 }}
                />
            </div>

            <Modal modal={modal} />

        </Contenedor>
    );
}

export default ChangePassword;