import styled from "styled-components"

export const QRs = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const WrapperQR = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
`;