import React from 'react'

import closeIco from '../../../assets/modal/close.png'

import { Player } from '@lottiefiles/react-lottie-player';
import * as ok from '../../../assets/modal/ok-animation.json'
import * as error from '../../../assets/modal/error-animation.json'

import './Modal.scss'
import { Button, Subtitle, Text } from 'library-my-service';

const Modal = ({
    modal,
    children
}) => {

    const {
        show,
        title,
        description,
        iconOk = false,
        iconError = false,
        onClose = null,
        clickOff = false,
        sbText = '',
        sbTheme = 'light-secondary',
        sbOnClick = () => { },
        pbText = '',
        pbTheme = 'light-secondary',
        pbOnClick = () => { },
    } = modal

    const backgroundHandler = () => {
        if (clickOff) onClose()
    }

    if (!show) return null;

    return (
        <div
            className='modalGenerico'
            onClick={backgroundHandler}
            data-testid='modal'
        >

            <div className='loaderContent'>

                {
                    onClose && (
                        <div
                            data-testid='cerrar'
                            className='closeButton'
                            onClick={onClose}>
                            <img src={closeIco} alt='close' className='close-ico' />
                        </div>
                    )
                }

                {
                    iconOk && (
                        <div className='lottie-icon'>
                            <Player
                                autoplay
                                loop={false}
                                src={ok}
                                keepLastFrame={true}
                            >
                            </Player>
                        </div>
                    )
                }{
                    iconError && (
                        <div className='lottie-icon'>
                            <Player
                                autoplay
                                loop={false}
                                src={error}
                                keepLastFrame={true}
                            >
                            </Player>
                        </div>
                    )
                }

                {/* <div className='descText'>{title}</div> */}

                <Subtitle
                    label={title}
                    isCenter
                    isBold
                />

                <Text
                    label={description}
                    style={{ marginTop: 16 }}
                />

                {
                    sbText && (
                        <Button
                            label={sbText}
                            onClick={sbOnClick}
                            theme={sbTheme}
                            style={{ marginTop: 16 }}
                        />
                    )
                }

                <Button
                    label={pbText}
                    onClick={pbOnClick}
                    theme={pbTheme}
                    isBold
                    style={{ marginTop: 16 }}
                />

                {
                    children
                }

            </div>

        </div>
    )
}

export default Modal