import React, { useEffect } from 'react'

import { Contenedor, Text, Title } from 'library-my-service';

import Verified from '../../components/verified/Verified';

import useConfirmEmailVerification from '../../../services/auth/useConfirmEmailVerification';

import Loading from '../../components/loading/Loading';

const VerifyAccount = () => {

    const urlParams = new URLSearchParams(window.location.search)
    const oobCode = urlParams.get('oobCode')

    const { isLoading, isError, callApi } = useConfirmEmailVerification()

    useEffect(() => {
        callApi({ oobCode })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return (
            <Contenedor marginTop={1}>
                <Loading />
            </Contenedor>
        )
    }

    if (isError) {
        return (
            <Contenedor marginTop={1}>

                <Title label='Verificar cuenta' />

                <Text
                    label='Lo siento mucho, pero no pudimos verificar tu cuenta en este momento. Esto puede deberse a que la información proporcionada es incorrecta o incompleta.'
                    style={{ marginTop: 16 }}
                />

                <Text
                    label='Si sigues teniendo problemas para verificar tu cuenta, ponte en contacto con nuestro equipo de soporte técnico para que puedan ayudarte.'
                    style={{ marginTop: 16 }}
                />

                <Text
                    label='Lamentablemente, no podremos permitirte el acceso a nuestra plataforma hasta que tu cuenta sea verificada correctamente. Agradecemos tu comprensión y esperamos que puedas resolver pronto cualquier problema que haya impedido la verificación de tu cuenta.'
                    style={{ marginTop: 16 }}
                />

                <Text
                    label='¡Gracias por tu paciencia!'
                    isBold
                    style={{ marginTop: 16, textAlign: 'right' }}
                />
                <Text
                    label='Y por confiar en nosotros'
                    isBold
                    style={{ marginTop: 4, textAlign: 'right' }}
                />

            </Contenedor>
        )
    }

    return (
        <Contenedor marginTop={1}>

            <Title label='Cuenta verificada' />

            <Text
                label='¡Gracias por verificar tu correo electrónico! Ahora que tu cuenta está activa, te invitamos a explorar todas las características y funcionalidades de nuestra aplicación.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='Si tienes alguna pregunta o necesitas ayuda, nuestro equipo de soporte está a tu disposición para ayudarte en cualquier momento.'
                style={{ marginTop: 16 }}
            />

            <Verified />

            <Text
                label='¡Gracias por unirte a nuestra comunidad!'
                isBold
                style={{ marginTop: 16, textAlign: 'right' }}
            />

            <Text
                label='El equipo de My Service'
                style={{ textAlign: 'right' }}
            />

        </Contenedor>
    );
}

export default VerifyAccount;