import React, { useEffect } from 'react'

import { Contenedor, Text, Subtitle, Title } from 'library-my-service';

import QRCode from 'react-qr-code';

import { colors } from '../../../styles/colors';

import { isAndroid, isIOS } from "react-device-detect";

import { WrapperQR, QRs } from './styled'

const android = 'https://play.google.com/store/apps/details?id=com.myservice'
const ios = 'https://apps.apple.com/app/my-service/id6464086392'

const DownloadAppScreen = () => {

    useEffect(()=> {

        initial()

    }, [])
    
    const initial = () => {
        if (isAndroid) window.location.href = android
		else if (isIOS) window.location.href = ios
    }
    
    return (
        <Contenedor marginTop={1}>

            <Title label='Descargá la App' />

            <Text
                label='Descargar nuestra app es re fácil, escancaneá el código QR desde tu celular, o buscá nuestro nombre en App Store o Play Store. Es rápida, fácil y gratis.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='¡Descargá nuestra app móvil ahora y sumate a nuestra comunidad! Gracias por elegirnos, ¡y esperamos que disfrutes de la experiencia que tenemos para vos!'
                style={{ marginTop: 16 }}
            />

            <QRs>

            <WrapperQR>

                <Subtitle label='Escaneá!' />

                <QRCode
                    size={256}
                    style={{ height: "auto", width: "100%", marginTop: 16 }}
                    value={'https://myservice.com.ar/download'}
                    fgColor={colors.darkSecondary}
                />

            </WrapperQR>

            {/* <WrapperQR>
                
                <Subtitle label='Android' />

                <QRCode
                    size={256}
                    style={{ height: "auto", width: "100%", marginTop: 16 }}
                    value={android}
                    fgColor={colors.darkSecondary}
                />

            </WrapperQR> */}
            
            </QRs>


        </Contenedor>
    )
}

export default DownloadAppScreen