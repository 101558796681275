import React from 'react'

import { Button, Contenedor, Subtitle, Text } from 'library-my-service';

import { useNavigate } from 'react-router-dom';

import './Professionals.scss'

const ProfessionalsScreen = () => {

    const navigate = useNavigate()

    return (

        <Contenedor marginTop={1}>

            <Subtitle
                label='Expandi tus servicios con My Service'
                isBold
                style={{ marginTop: 16 }}
            />

            <Text
                label='¿Estás buscando una forma fácil y gratuita de encontrar nuevos clientes? Te ofrecemos una plataforma en línea que te permite conectarte con clientes que necesitan los servicios que ofreces, y todo esto de manera totalmente gratuita y sin comisiones. '
                style={{ marginTop: 16 }}
            />

            <Text
                label='En nuestra aplicación, solo trabajan profesionales verificados, lo que garantiza una experiencia de usuario positiva y segura para todos los involucrados.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='Además, nuestra aplicación te ofrece un código de seguridad y un código QR para escanear cuando presten servicios con un cliente. Esto asegura que solo el cliente con el código de seguridad correcto pueda acceder a tus servicios, lo que aumenta la confianza y seguridad en la plataforma.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='En resumen, si te unes a nuestra aplicación, tendrás acceso a una amplia base de clientes y podrás expandir tu negocio de manera efectiva y segura, todo esto de manera gratuita. Además, con nuestro código de seguridad y código QR, te ofrecemos una capa adicional de seguridad y confianza. ¡No dudes en unirte a nuestra plataforma y empezar a disfrutar de los beneficios que ofrecemos a nuestros profesionales verificados!'
                style={{ marginTop: 16 }}
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 24 }}>
                <Button
                    label='Contactate'
                    theme='primary'
                    isBold
                    onClick={() => navigate(`/contact`) }
                />
            </div>

        </Contenedor>
    );
}

export default ProfessionalsScreen;