import { Text } from 'library-my-service';

import React from 'react'

// import facebookIco from '../../../assets/ico/facebook-verde.png'
import instagramIco from '../../../assets/ico/instagram-verde.png'
import whatsappIco from '../../../assets/ico/whatsapp-verde.png'

import './footer.scss'

const Footer = () => {
    return (
        <footer className='footer'>
            {/* 
            <Button
                label='Descargá la App'
                theme='secondary'
                scale={60}
                isBold
            /> */}

            <div className='footer-atencion-cliente mt-mobile'>
                <Text
                    label='Atención al cliente'
                    // color='white'
                    isBold
                    size='small'
                    style={{ fontSize: 12 }}
                />
                <Text
                    label='info@myservice.com.ar'
                    // color='white'
                    style={{ marginTop: 4, fontSize: 12 }}
                />
            </div>

            <div className='footer-copyright mt-mobile'>
                <Text
                    label={`Copyright © 2023 My Service. Todos los derechos reservados.`}
                    style={{ fontSize: 12, textAlign: 'center' }}
                />
            </div>

            <div className='footer-redes mt-mobile'>
                <Text
                    label='Seguinos en nuestras redes'
                    // color='white'
                    isBold
                    style={{ fontSize: 12 }}
                />
                <div className='div-ico-app-footer'>

                    {/* <div onClick={() => window.open('https://www.facebook.com/', '_blank', 'noopener,noreferrer')}>
                        <img src={facebookIco} alt='facebook-link' className='ico-app-footer' />
                    </div> */}

                    <div onClick={() => window.open('https://instagram.com/myservice.app', '_blank', 'noopener,noreferrer')}>
                        <img src={instagramIco} alt='facebook-link' className='ico-app-footer' />
                    </div>

                    <div onClick={() => window.open('https://wa.me/+5491149144362', '_blank', 'noopener,noreferrer')}>
                        <img src={whatsappIco} alt='whatsapp-link' className='ico-app-footer' />
                    </div>

                </div>
            </div>

        </footer>
    );
}

export default Footer;