import React from 'react'

import { Contenedor, Text, Title } from 'library-my-service';

const Sended = () => {

    return (
        <Contenedor marginTop={1}>

            <Title label='Consulta enviada' />

            <Text
                label='Te informamos que hemos recibido tu consulta y queremos agradecerte por contactarnos. Tu mensaje ha sido enviado con éxito y nuestro equipo de atención al cliente lo revisará lo antes posible.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='Por favor, tené en cuenta que nos esforzamos por proporcionar un excelente servicio al cliente y haremos todo lo posible para responder a su consulta lo antes posible. Si necesita alguna información adicional o tiene alguna pregunta, no dude en ponerse en contacto con nosotros nuevamente.'
                style={{ marginTop: 16 }}
            />

            <Text
                label='El equipo de My Service'
                isBold
                style={{ marginTop: 16, textAlign: 'right' }}
            />

        </Contenedor>
    )
}

export default Sended