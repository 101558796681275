import React from 'react'

import { Contenedor, Subtitle, Text } from 'library-my-service';

import './FrecuentsQuestionsScreen.scss'

const FrecuentsQuestionsScreen = () => {

    return (

        <Contenedor marginTop={1}>

            <Subtitle
                label='Preguntas frecuentes'
                isBold
                style={{ marginTop: 16 }}
            />
        
            <Text
                label='¿Dónde puedo descargar la aplicación?'
                isBold
                style={{ marginTop: 16 }}
            />
            <Text
                label='La aplicación estará disponible tanto para Android como iOS. La podrás encontrar en tu respectivo Play Store.'
                style={{ marginTop: 4 }}
            />
        
            <Text
                label='¿La aplicación tiene algún costo?'
                isBold
                style={{ marginTop: 16 }}
            />
            <Text
                label='No, esta aplicación es totalmente gratuita y te permite brindar servicios de manera fácil y segura sin ningún costo adicional.'
                style={{ marginTop: 4 }}
            />
        
            <Text
                label='¿Cómo funciona esta aplicación?'
                isBold
                style={{ marginTop: 16 }}
            />
            <Text
                label='Solo tenés que descargar la aplicación, registrarte y buscar el servicio que necesitas. Luego, puedes seleccionar al profesional verificado que más te guste y la aplicación te brindará un código de seguridad.'
                style={{ marginTop: 4 }}
            />
        
            <Text
                label='¿Necesito tener un buen celular para poder usarla?'
                isBold
                style={{ marginTop: 16 }}
            />
            <Text
                label='La aplicación está optimizada para cualquier celular y te permite contratar servicios de manera rápida y fácil, lo que ahorra tiempo y te brinda comodidad.'
                style={{ marginTop: 4 }}
            />
        
            <Text
                label='¿Qué tipos de servicios están disponibles en esta aplicación?'
                isBold
                style={{ marginTop: 16 }}
            />
            <Text
                label='Puedes encontrar una amplia variedad de servicios disponibles en esta aplicación, desde electricistas hasta servicios de jardinería y mucho más.'
                style={{ marginTop: 4 }}
            />
        
            <Text
                label='¿Esta aplicación estará disponible para todo el País?'
                isBold
                style={{ marginTop: 16 }}
            />
            <Text
                label='No, esta aplicación está disponible por el momento en todos los barrios de Comuna 11'
                style={{ marginTop: 4 }}
            />
        
            <Text
                label='¿Cómo se asegura la aplicación de que los profesionales estén verificados?'
                isBold
                style={{ marginTop: 16 }}
            />
            <Text
                label='La aplicación realiza un riguroso proceso de verificación antes de aceptar a cualquier profesional en su plataforma, lo que garantiza la calidad y confiabilidad del servicio.'
                style={{ marginTop: 4 }}
            />
        

        </Contenedor>
    );
}
 
export default FrecuentsQuestionsScreen;