export const colors = {
    primary: '#F0B27A',
    darkPrimary: '#A57548',
    lightPrimary: '#FDF2E9',

    secondary: '#5296a5',
    darkSecondary: '#377785',
    lightSecondary: '#E7F2F3',
    
    white: '#FFFFFF',
    gray: '#DDDDDD',
    lightGray: '#f5f5f5',
    darkGray: '#4c4c4c',
    black: '#000000',

    red:'#FF6961',
    lightRed:'#FFA39E',

    green: '#0AC29F',
    lightGreen: '#DBFFE8',
}